import React, { useEffect, useRef, useState } from "react";
import {
  CallApi,
  createOurSageLog,
  isDigit,
  isValidVerificationCode,
  logOurSageEvent,
  MapObjectToQueryString,
  METHOD,
  sendGAEvent,
} from "../helpers";
import { Button } from "./Common";
import { PatientDetails, Session } from "../models";

interface Props {
  session: Session;
  patient: PatientDetails;
  setIsError: Function;
  nextClick: Function;
}
const EnterVerificationCode = React.memo(
  ({ session, patient, setIsError, nextClick }: Props) => {
    const inputRefs = useRef(new Array<HTMLInputElement>());
    const [code, setCode] = useState("");
    const [errorClass, setErrorClass] = useState("");
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [isFocusButton, setIsFocusButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [incorrectVerificationCode, setIncorrectVerificationCode] =
      useState(false);
    const verificationCode = useRef("");

    const handleChange = (e: any): void => {
      if (!isDigit(e.key) && e.key !== "Backspace") {
        return;
      }

      setErrorClass("");
      const { id } = e.target;
      const suffix = Number(id.substring(id.length - 1));
      const currentInput = inputRefs.current.at(suffix - 1);

      if (currentInput && currentInput.value !== e.key && isDigit(e.key)) {
        // The user is replacing a digit
        currentInput.value = e.key;
      }
      const newCode = inputRefs.current.reduce(
        (accum, input) => accum + (input?.value != null ? input.value : ""),
        ""
      );
      setCode(newCode);
      if (isValidVerificationCode(newCode)) {
        setIsFocusButton(true);
        setIsButtonActive(true);
      } else {
        setIsFocusButton(false);
        setIsButtonActive(false);
      }

      if (e.key === "Backspace") {
        if (suffix > 1) {
          const prevInput = inputRefs.current.at(suffix - 2);
          prevInput?.focus();
        }
      } else if (suffix < 4) {
        const nextInput = inputRefs.current.at(suffix);
        nextInput?.focus();
      }
    };

    const verifyNext = () => {
      if (isLoading) {
        return;
      }
      if (isValidVerificationCode(code)) {
        callVerification(code);
      } else {
        setErrorClass("error");
        let focusSet = false;
        inputRefs.current.forEach((input) => {
          if (!focusSet && !isDigit(input.value)) {
            input.focus();
            focusSet = true;
          }
        });
      }
    };

    const callVerification = async (enteredCode: string): Promise<void> => {
      try {
        setIsLoading(true);
        setIsError(false);
        setIncorrectVerificationCode(false);
        const body = MapObjectToQueryString({
          cid: session.cid,
          code: verificationCode.current,
          full_vcode: enteredCode,
          code_length: 4,
        });

        const ourSageLog1 = createOurSageLog(patient, session, null);
        ourSageLog1.function = "calling API verify_patient";

        const response = await CallApi(
          "verify_patient",
          METHOD.POST,
          body,
          null,
          setIsLoading,
          setIsError,
          ourSageLog1
        );

        const ourSageLog2 = createOurSageLog(patient, session, null);
        ourSageLog2.function = "validate entered verification code";
        ourSageLog2.paymentAmount = session.repeatPrice;
        ourSageLog2.success = Number(response.stat) === 1;
        await logOurSageEvent(ourSageLog2);

        if (Number(response.stat) === 1) {
          sendGAEvent({
            event: "personal_verification",
            step: 6,
            step_name: "Personal Verification",
            user_id: patient.maskedEmail,
          });
          nextClick(true);
        } else {
          setIncorrectVerificationCode(true);
        }
      } catch (e) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    const reSendVerificationCode = async (): Promise<void> => {
      try {
        setIncorrectVerificationCode(false);
        const formData = new FormData();
        formData.append("pmob", patient.mobileNo);
        formData.append("pemail", patient.email);
        formData.append("vopt", session.vopt);
        formData.append("cid", session.cid.toString());
        formData.append("code_length", "4");
        formData.append("brand", "scripts");

        const ourSageLog = createOurSageLog(patient, session, null);
        ourSageLog.function = `re send verification code to ${session.vopt === "M" ? "mobile" : "email"}`;

        const response = await CallApi(
          "code_patient",
          METHOD.POST,
          formData,
          null,
          setIsLoading,
          setIsError,
          ourSageLog
        );

        if (Number(response.stat) === 1) {
          verificationCode.current = response.msg;
        }
      } catch (e) {
        setIsError(true);
      }
    };

    useEffect(() => {
      const firstInput = inputRefs?.current?.at(0);
      if (firstInput) {
        firstInput.focus();
      }
      verificationCode.current = session.verificationCode;
    }, [inputRefs, session.verificationCode]);

    return (
      <>
        <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
          <div className="col-11">
            <p className="cs-chkin-form-step-heading mb-4">
              Enter verification code
            </p>
            {(!incorrectVerificationCode || isLoading) && (
              <div
                className="blue-content-box d-flex mb-5 p-4"
                id="code_message"
              >
                {isLoading && <div className="loader"></div>}
                {!isLoading && (
                  <img
                    src="images/svg/Icon_Information_blue.svg"
                    className="mr-3 info-icon"
                    alt="info icon"
                  />
                )}
                <p className="body-text black-80 m-0" id="code_waiting">
                  Please wait a few minutes to receive your code.
                </p>
              </div>
            )}
            {incorrectVerificationCode && !isLoading && (
              <div className="d-flex error-box mb-5 p-4 " id="code_message">
                <img
                  src="images/svg/Icon_Error_Red.svg"
                  width="16"
                  className="mr-3 warning-icon"
                  alt="warning icon"
                />
                <p className="body-text black-80 m-0" id="code_failed">
                  Verification code is incorrect!
                </p>
              </div>
            )}
            <div
              className={`form-group float-label-control vcode vcode-input-form d-flex justify-content-center columnGap-1  ${errorClass}`}
            >
              {[1, 2, 3, 4].map((index) => (
                <input
                  type="number"
                  className={`vcode-control col-sm-2 col-2`}
                  id={`vcode${index}`}
                  name="vcode"
                  maxLength={1}
                  key={`input-${index}`}
                  data-t="n"
                  data-req="true"
                  ref={(element: HTMLInputElement) =>
                    (inputRefs.current[index - 1] = element)
                  }
                  onKeyUp={handleChange}
                />
              ))}
            </div>

            <div className="form-group">
              <button
                className="btn btn-lg btn-primary js-btn-prev d-none"
                type="button"
                title="Prev"
              ></button>
              <Button
                label="Validate"
                handleClick={() => {
                  verifyNext();
                }}
                isLoading={isLoading}
                isActive={isButtonActive}
                isFocus={isFocusButton}
              />
              <div className="mt-5">
                <button
                  className="btn btn-block btn-text d-flex justify-content-center columnGap-2"
                  type="button"
                  id="resend_code_text"
                >
                  <span className="btn-text-normal">
                    Didn't receive a code?
                  </span>
                  <span
                    className="link-text"
                    onClick={() => reSendVerificationCode()}
                  >
                    Resend code
                  </span>
                </button>
              </div>
            </div>

            <div>
              <button
                className="btn btn-lg btn-text fade btn-resend-code-modal"
                hidden
                id="resend_code_text"
                type="button"
              >
                <span className="btn-text-normal">Didn't receive a code?</span>
                <span className="link-text">Resend code</span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default EnterVerificationCode;
